import React, { Fragment } from "react"
import Img, { FluidObject } from "gatsby-image"

type Slice = {
  internal: { type: string }
  image: { fluid: FluidObject }
  value: any
}

interface StandardContentAreaProps {
  body: Array<Slice>
}

interface StandardContentSlicesProps {
  slices: Array<Slice>
}

// Sort and display the different slice options
function StandardContentSlices({
  slices,
}: StandardContentSlicesProps): JSX.Element {
  if (!slices.length) return <Fragment>No component match</Fragment>
  const result = slices.map((slice: Slice, index: number) => {
    switch (slice.internal.type) {
      case "ContentfulPageCopy":
        return (
          <Fragment key={index}>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum
              placeat, perspiciatis vero molestias, iusto at repellendus
              assumenda facilis commodi tempora laboriosam magni soluta
              temporibus ipsum animi non enim rem velit?
            </p>
          </Fragment>
        )
      case "ContentfulPageImage":
        return (
          <Img
            key={index}
            className="standout-image"
            fluid={slice.image.fluid}
          />
        )
      default:
        return <Fragment>No component match</Fragment>
    }
  })

  return <>{result}</>
}

export default ({ body }: StandardContentAreaProps) => {
  return <StandardContentSlices slices={body} />
}
