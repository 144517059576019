import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Service from "./Service"

const query = graphql`
  query AllServices {
    allDatoCmsServiceListing {
      nodes {
        url
      }
    }
    allDatoCmsService {
      nodes {
        title
        url
      }
    }
  }
`

const AllServices = ({ data }) => {
  const { allDatoCmsService, allDatoCmsServiceListing } = data
  const { url: parentUrl } = allDatoCmsServiceListing.nodes[0]

  return allDatoCmsService.nodes.map((service, index) => {
    return (
      <Service
        key={`service-${index}`}
        data={{ title: service.title, url: `/${parentUrl}/${service.url}` }}
      />
    )
  })
}

export default () => {
  return (
    <StaticQuery query={query} render={(data) => <AllServices data={data} />} />
  )
}
