import React from "react"
import { Link } from "gatsby"

interface HeroProps {
  title: string
  subtitle?: string
  mainImage: {
    url: string
  }
  link?: { title: string; url: string }
  className?: string | boolean
  variation?: string
}

const Hero = ({
  title,
  subtitle,
  mainImage,
  link,
  className = false,
  variation,
}: HeroProps) => {
  return (
    <div className={`hero ${className ?? className} ${variation}`}>
      <div className="hero__inner [ container -pinched ]">
        {title && (
          <h1 className="hero__title [ title h1 -white -no-margin ]">
            <span>{title}</span>
          </h1>
        )}
        {subtitle && <p className="hero__subtitle">{subtitle}</p>}
        {link && (
          <div className="hero__button [ button-holder centered ]">
            <Link className="[ button button--secondary ]" to={link.url}>
              {link.title}
            </Link>
          </div>
        )}
      </div>
      {mainImage && (
        <div className="hero__media">
          <img src={mainImage.url} className="hero__image" />
        </div>
      )}
    </div>
  )
}

export default Hero
