import React from "react"

interface ArrowProps {
  className: string
}

export default ({ className }: ArrowProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="23"
      viewBox="0 0 13 23"
      className={className}
    >
      <g fillRule="evenodd">
        <g fill="currentColor" fillRule="nonzero">
          <path
            d="M705.316 483l.684.746-11.745 10.754L706 505.254l-.684.746-12.152-11.127c-.194-.178-.216-.47-.065-.674l.065-.072L705.316 483z"
            transform="translate(-693 -483) matrix(-1 0 0 1 1399 0)"
          />
        </g>
      </g>
    </svg>
  )
}
