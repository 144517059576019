import React from "react"
import { Link } from "gatsby"

export const Service = ({ data }) => {
  const { title, url } = data
  return (
    <div className="card">
      <Link to={url}>
        <div className="card__inner">
          <h4 className="title -underline -no-margin ">
            <span>{title}</span>
          </h4>
        </div>
      </Link>
    </div>
  )
}

export default Service
