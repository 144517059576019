import React from "react"
import { Link } from "gatsby"
import Service from "./Service"
import AllServices from "./AllServices"

interface ServiceOverviewTypes {
  data: {
    title?: string
    content?: string
    featuredServices: []
    showAllServices: boolean
  }
}

export const ServiceOverview = ({ data }: ServiceOverviewTypes) => {
  const { title, content, featuredServices, showAllServices } = data

  const showFeaturedServices = Boolean(featuredServices.length)
  return (
    <div className="[ section -align-center ]">
      <div className="container -pinched">
        <h2 className="[ title -underline ]">
          <span>{title}</span>
        </h2>
        <p>{content}</p>
      </div>
      <div className="container">
        <div className="services-grid">
          {showFeaturedServices &&
            featuredServices.map((service, index) => {
              return (
                <Service data={{ title: service.title, url: service.url }} />
              )
            })}
          {showAllServices && <AllServices />}
        </div>
      </div>
      {showAllServices && (
        <div className="container -pinched">
          <p>
            <Link to="/services" className="button">
              View all services
            </Link>
          </p>
        </div>
      )}
    </div>
  )
}

export default ServiceOverview
