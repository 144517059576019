import React from "react"
import { StructuredText } from "react-datocms"

interface TextBlockTypes {
  data: {
    title?: string
    text?: {
      value: any
    }
    backgroundText?: string
  }
}

export const TextBlock = ({ data }: TextBlockTypes) => {
  const { title, text, backgroundText } = data
  return (
    <div className="[ container -pinched ]">
      {title && (
        <h2 className="[ title -underline ]">
          <span>{title}</span>
        </h2>
      )}
      {backgroundText && <p className="handwriting">{backgroundText}</p>}
      <StructuredText data={text?.value} />
    </div>
  )
}

export default TextBlock
