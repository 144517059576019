import React from "react"
import { StructuredText } from "react-datocms"
import { TextBlock } from "../slices"
import ServiceOverview from "./ServiceOverview"
import TextBoxWithImage from "./TextBoxWithImage"
import MeetTheTeam from "./MeetTheTeam"
import GalleryBlock from "./GalleryBlock"

interface Slices {
  slices: Slice[]
  variation: false | string
}

interface Slice {
  __typename: string
  title?: string
  content?: string
  backgroundText?: string
  text?: {
    value: any
  }
  image?: {
    url: string
  }
  link?: {
    url: string
  }
  linkText?: string
  alignment?: string
}

const SliceRenderer: Function = ({ slices, variation = false }: Slices) => {
  // Sort and display the different slice options

  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.__typename) {
        case "DatoCmsText":
          return (
            <div
              className={`[ section ${variation && `-${variation} `}] intro`}
            >
              <TextBlock data={slice} />
            </div>
          )

        case "DatoCmsImage":
          return (
            <div className={`[ section ${variation && `-${variation} `} ]`}>
              <img src={slice?.image?.url} className="content-image" />
            </div>
          )

        case "DatoCmsTextBoxWithImage":
          return <TextBoxWithImage data={slice} />

        case "DatoCmsServiceOverview":
          return <ServiceOverview data={slice} />

        case "DatoCmsMeetTheTeam":
          return <MeetTheTeam data={slice} />

        case "DatoCmsGalleryBlock":
          return <GalleryBlock data={slice} />

        default:
          return <h1>Missing slice - {slice.__typename}</h1>
      }
    })()
    return res
  })
}

export default SliceRenderer
