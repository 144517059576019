import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Logo from "../../../static/images/bijou-logo-large.png"
import FooterLinks from "./FooterLinks"
import { IconFacebook, IconInstagram } from "../svg"

const Footer = ({ data }) => {
  const { instagramUrl, facebookUrl, salonAddress, openingHours: hours } = data
  const year = new Date().getFullYear()

  const { openingHours } = JSON.parse(hours)

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <div className="footer__brand">
            <img src={Logo} alt="" className="footer__logo" />
            <div className="social-icons -horizontal">
              {facebookUrl && (
                <a
                  href={facebookUrl}
                  target="_blank"
                  className="social-icons__icon -facebook"
                >
                  <IconFacebook />
                </a>
              )}
              {instagramUrl && (
                <a
                  href={instagramUrl}
                  target="_blank"
                  className="social-icons__icon -instagram"
                >
                  <IconInstagram />
                </a>
              )}
            </div>
          </div>
          <FooterLinks />
          <span>
            <h3 className="title -underline -white h6">
              <span>Opening hours</span>
            </h3>
            {openingHours && openingHours.length && (
              <table className="opening-hours">
                <tbody>
                  {openingHours.map((entry, index) => {
                    const [day, time] = entry

                    return (
                      <tr>
                        <td>{day}</td>
                        <td>{time}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </span>
        </div>
      </div>
      <div className="footer__copyright">
        <div className="container">
          <p className="footer__infotext">Registed office: {salonAddress}</p>
          <p>&copy; Bijou Creative Hair {year}. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

const query = graphql`
  query Footer {
    allDatoCmsGlobal {
      nodes {
        instagramUrl
        facebookUrl
        salonAddress
        openingHours
      }
    }
  }
`

export default () => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <Footer data={data.allDatoCmsGlobal.nodes[0]} />}
    />
  )
}
