import React from "react"
import "photoswipe/dist/photoswipe.css"

import { Gallery, Item } from "react-photoswipe-gallery"
import { Link } from "gatsby"

interface GalleryTypes {
  data: {
    title: string
    content: string
    gallery: { url: string }[]
    link: { url: string }
  }
}

export const GalleryBlock = ({ data }: GalleryTypes) => {
  const { title, content, gallery, link } = data
  return (
    <div className="section gallery">
      <div className="[ container -pinched ]">
        <h2 className="[ title -underline ]">
          <span>{title}</span>
        </h2>
        <p>{content}</p>
      </div>
      <div className="container">
        <div className="gallery-grid">
          {gallery.map((image) => {
            return (
              <div className="gallery-grid__item">
                <img src={image.url} alt="" />
              </div>
            )
          })}
        </div>
        <Link to={link.url} className="button">
          View gallery
        </Link>
      </div>
    </div>
  )
}

export default GalleryBlock
