import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { getApplicationState } from "../../redux/reducers/applicationState/applicationStateSelector"
import { Link, graphql, StaticQuery } from "gatsby"
import actions from "../../redux/actions-index"

const query = graphql`
  query Navigation {
    allDatoCmsNavigation {
      nodes {
        pages {
          __typename
          ... on DatoCmsGallery {
            title
            url
          }
          ... on DatoCmsStandardContentPage {
            title
            url
            title
          }
          ... on DatoCmsServiceListing {
            title
            url
          }
          ... on DatoCmsTeamListing {
            title
            url
          }
        }
      }
    }
  }
`

interface pages {
  pages: [page]
}

type page = {
  id: number
  url: string
  title: string
}

const Navigation = ({ data }) => {
  const dispatch = useDispatch()
  const { pages } = data.allDatoCmsNavigation.nodes[0]
  const { navigationActive } = useSelector(getApplicationState)

  const onClick = () => {
    dispatch(actions.setNavigationActive(false))
  }

  return (
    <nav className={navigationActive ? "navigation active" : "navigation"}>
      {pages.map((item: page, index: number) => {
        const { title, url } = item
        return (
          <Link
            className="navigation__link"
            activeClassName="active"
            to={`/${url}`}
            onClick={onClick}
            key={`navigationItem-${index}`}
          >
            {title}
          </Link>
        )
      })}
    </nav>
  )
}

export default () => {
  return (
    <StaticQuery query={query} render={(data) => <Navigation data={data} />} />
  )
}
