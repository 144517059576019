import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

const Footer = ({ data }) => {
  const { links } = data
  return (
    <span style={{ alignSelf: "flex-start" }}>
      <h3 className="title -underline -white h6">
        <span>Useful Links</span>
      </h3>
      <nav className="footer-links">
        <Link
          className="footer-links__link"
          activeClassName="active"
          to="/"
          key={`FooterLink-Home`}
        >
          Home
        </Link>
        {links.map((item, index) => {
          const { title, url } = item
          return (
            <Link
              className="footer-links__link"
              activeClassName="active"
              to={`/${url}`}
              key={`FooterLink-${index}`}
            >
              {title}
            </Link>
          )
        })}
      </nav>
    </span>
  )
}

export const query = graphql`
  query FooterLinks {
    allDatoCmsFooter {
      nodes {
        links {
          ... on DatoCmsGallery {
            title
            url
          }
          ... on DatoCmsStandardContentPage {
            title
            url
            title
          }
          ... on DatoCmsServiceListing {
            title
            url
          }
          ... on DatoCmsTeamListing {
            title
            url
          }
        }
      }
    }
  }
`

export default () => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <Footer data={data.allDatoCmsFooter.nodes[0]} />}
    />
  )
}
