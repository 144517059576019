import { createSelector } from "reselect"

interface StateProps {
  applicationState: {
    navigationActive: boolean
  }
}

export const getApplicationState = createSelector(
  (state: StateProps) => state.applicationState,
  (applicationState) => applicationState
)
