import React from "react"
import { Link } from "gatsby"

interface MeetTheTeamTypes {
  data: {
    title: string
    subtext?: string
    backgroundImage: {
      url: string
    }
    link: {
      url: string
    }
  }
}

export const MeetTheTeam = ({ data }: MeetTheTeamTypes) => {
  const { title, subtext, link, backgroundImage } = data
  return (
    <>
      <div className="section meet-the-team">
        <div className="[ container -pinched-large ]">
          <h3 className="[ title -white -underline ]">
            <span>{title}</span>
          </h3>
          <p>{subtext}</p>
          <Link to={link.url} className="button button--secondary">
            Meet the team
          </Link>
        </div>
        <img src={backgroundImage.url} alt="" />
      </div>
    </>
  )
}

export default MeetTheTeam
