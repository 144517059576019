import React from "react"
import { Link } from "gatsby"

interface IconButtonLinkProps {
  to: string
  state?: object
  children: JSX.Element | JSX.Element[]
  className?: string
}

export default ({ to, state, children, className }: IconButtonLinkProps) => {
  return (
    <Link className={`icon-button ${className}`} to={to} state={{ state }}>
      {children}
    </Link>
  )
}
