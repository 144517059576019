import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getApplicationState } from "../../redux/reducers/applicationState/applicationStateSelector"
import { IconPhone } from "../svg"
import { Link, StaticQuery, graphql } from "gatsby"
import { IconHamburger, IconClose, IconEnvelope, IconHome } from "../svg"
import Navigation from "./Navigation"
import actions from "../../redux/actions-index"

interface HeaderProps {
  className?: string
}

const Header = ({ data, props }: HeaderProps) => {
  const { salonAddress, telephoneNumber, contactEmail } =
    data.allDatoCmsGlobal.nodes[0]
  const dispatch = useDispatch()
  const { navigationActive } = useSelector(getApplicationState)
  const navigationToggle = () => {
    dispatch(actions.setNavigationActive(!navigationActive))
  }
  return (
    <header className={`header ${props}`}>
      <div className="header-masthead">
        <div className="container">
          <div className="header-masthead__inner">
            {salonAddress && (
              <p>
                <IconHome />
                {salonAddress}
              </p>
            )}
            {telephoneNumber && (
              <p>
                <IconPhone />
                {telephoneNumber}
              </p>
            )}
            {contactEmail && (
              <p>
                <IconEnvelope />
                {contactEmail}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="header__inner container">
        <button
          className={`header__nav-toggle ${navigationActive ? "active" : ""}`}
          onClick={navigationToggle}
        >
          {navigationActive ? <IconClose /> : <IconHamburger />}
        </button>
        <Link className="header__link" to="/">
          <span>Bijou</span>
        </Link>
        <Navigation />
        {telephoneNumber && (
          <a className="navigation__link call" href={`tel:${telephoneNumber}`}>
            <IconPhone />
            <span>{telephoneNumber}</span>
          </a>
        )}
      </div>
    </header>
  )
}

export const query = graphql`
  query Header {
    allDatoCmsGlobal {
      nodes {
        salonAddress
        telephoneNumber
        contactEmail
      }
    }
  }
`

export default ({ className }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <Header data={data} props={className} />}
    />
  )
}
