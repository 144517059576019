import React from "react"

interface HamburgerProps {
  className?: string
}

export default ({ className }: HamburgerProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 13"
      className={className}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor">
          <path
            d="M640 1627v1h-10v-1h10zm10-6v1h-20v-1h20zm0-6v1h-10v-1h10z"
            transform="translate(-630 -1615)"
          />
        </g>
      </g>
    </svg>
  )
}
