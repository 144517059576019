import { link } from "fs"
import { Link } from "gatsby"
import React from "react"
import { StructuredText } from "react-datocms"

interface TextBoxWithImageTypes {
  data: {
    title?: string
    content?: string
    backgroundText?: string
    image: {
      url: string
    }
    link: {
      url: string
    }
    linkText?: string
    alignment?: boolean
  }
}

export const TextBoxWithImage = ({ data }: TextBoxWithImageTypes) => {
  const { title, content, backgroundText, image, link, linkText, alignment } =
    data
  return (
    <div className="section">
      <div className="container">
        <div className="about-me__inner">
          <div className="about-me__content">
            <div className="about-me__content-inner">
              <h3 className="[ title h3 -underline ]">
                <span>{title}</span>
              </h3>
              <p>{content}</p>
              <Link className="button" to={link.url}>
                {linkText}
              </Link>
            </div>
            <p className="handwriting">{backgroundText}</p>
          </div>
          <div className="about-me__media">
            <img src={image.url} alt="" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextBoxWithImage
